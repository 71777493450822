<template>
    <div class="content-wrapper">
        <div class="content">
            <h1 class="dashboard">OAuth</h1>
            <SpinnerLoader></SpinnerLoader>
        </div>
    </div>
</template>

<script lang="js">

import SpinnerLoader from '../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../vars/general'
export default {
    name: 'OAuthLogin',
    components: { SpinnerLoader },
    data: () => ({

    }),
    computed: {

    },
    watch: {
        '$route': {
                handler() {
                    this.redirectToCreateConnector()
                },
                deep: true,
                immediate: true,
            }
    },
    methods: {
        redirectToCreateConnector(){
            const query = this.$route.query
            const state = JSON.parse(query.state)
            if(!state){
                this.$router.push('/projects')
                return
            }
            switch (state.connectorType) {
                case CONNECTOR_TYPES.amoCrm:
                    this.$store.commit('project/updateCurrentAddingConnector', this.$store.state.connectorsList.find(el=>el.type===state.connectorType))
                    this.$router.push({path:`/project/${state.projectId}/addConnectorStage2`, query:{code:query.code, referer:query.referer, connectorId:state.connectorId}})
                    break;
                case CONNECTOR_TYPES.amoCrmFull:
                    this.$store.commit('project/updateCurrentAddingConnector', this.$store.state.connectorsList.find(el=>el.type===state.connectorType))
                    this.$router.push({path:`/project/${state.projectId}/addConnectorStage2`, query:{code:query.code, referer:query.referer, connectorId:state.connectorId}})
                    break;

                default:
                    break;
            }
        }
    },
    created() {

    },
}
</script>
<style lang="sass" scoped></style>
